import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { fetchPostalCodes,setUserName } from '../../redux/Actions';
import { setValue } from '../../utility';
import { fetchGetShopByPinCode } from '../../redux/Actions/checkoutPageActions';
import { cleancloths,vehicle,priorityicon1,priorityicon2,priorityicon3,priorityimg1,priorityimg2,priorityimg3,appdownload,mobicon1,mobicon2,mobicon3,appstore,googleplay,qrcode,laundrybag1,wing1,wing2,wing3,wing4,dicon1,dicon2,dicon3,dicon4,dicon5,dicon6,testimonialuser1,testimonialuser2,testimonialuser3,testimonialimg1,testimonialimg2,testimonialimg3,faqimg,trustimg,titleborder,serviceicon2,serviceicon3,serviceicon4,laundryicon1,laundryicon2,laundryicon3,laundryicon4,laundryicon5,laundryicon6,laundryicon7,laundryicon8,laundryicon9,tick,play,banner1,banner2,banner3,banner4,banner5
} from '../../assets/img'
import SampleVideo from './../../images/sample.mp4';
import { Accordion } from "react-bootstrap";
import { fetchFaqData } from "../../redux/Actions/policyActions";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from 'axios';
import { DebounceInput } from 'react-debounce-input';
// import BookNow from './index';

// const postalCode = [
//     { content: "GU111BH" },
//     { content: "GU111KD" },
//     { content: "GU111MH" },
// ];

function BookNow() {
    const [postalCodeList, setPostalCodeList] = useState([])
    const [postalCodeValue, setPostalCodeValue] = useState("")
    const dispatch = useDispatch<any>();
    const state: any = useSelector(state => state);
    const navigate = useNavigate();

    const base_url = process.env.REACT_APP_BACKEND_URL;

    // const base_url = "https://revamp.dreamguystech.com"


    // const fetchData = async () => {
    //     const base_url = "https://revamp.dreamguystech.com";
    //     try {
    //         const bodyFormData = new FormData();
    //         bodyFormData.append('keyword', 'Gu11');
    //         // res.header('Access-Control-Allow-Methods', 'GET, POST');
    //         const response = await axios({
    //             method: "post",
    //             url: `${base_url}/api/get_postal_codes`,
    //             data: bodyFormData,
    //             headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST' },
    //         })
    //         // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
    //         // const result = await response.then(response => response);

    //         if (response) {
    //             setPostalCodeList(response.data.data[0])
    //             
    //         }
    //     } catch (error) {
    //         
    //     }
    // }

    const settingsData: any = useSelector<any>(
        (state) => state?.settings
    );

    // const [productList, setProductList] = useState<any | null>({})
    const [blogList, setBlogList] = useState<any | null>({})
    useEffect(() => {

        const headers = {
            'Accept': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }

        // axios.get(`${base_url}/api/get_categories?group_id=1`, {
        //     headers: headers

        // }).then(e => {
        //     setProductList({ data: e.data })
        //     // e.data.data.category.slice(0, 1).map((category: any) =>
        //     //     setActive(category.main_category_name)
        //     // )
        // })

        axios.get(`${base_url}/api/blog_list?`, {
            headers: headers

        }).then(e => {
            setBlogList(e.data.data)
            // e.data.data.category.slice(0, 1).map((category: any) =>
            //     setActive(category.main_category_name)
            // )
        })
    }, [])

    const faqData: any = useSelector<any>((state) => state?.faq);
    useEffect(() => {
        dispatch(fetchFaqData());
    }, []);

    useEffect(() => {
        if (state) {
            setPostalCodeList(state.postalCodeList);
            // setValue("postalCode",state.postalCodeList[0]?.postcode)
        }

    }, [state])

    const Url = window.location.host
    const blogUrl = `https://blog.${Url}`

    // useEffect(() => {
    //     // fetchData();
    //     dispatch(fetchPostalCodes())
    //     // dispatch(fetchPostalCodes());
    // }, [])

    const handleChange = (e: any) => {
        const result = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
        // setPostalCodeList(e.target.value);
        const { value } = e.target;
        const updateValue = value.replace(/\s/g,'')
        if (value.length > 0) {
            dispatch(fetchPostalCodes(updateValue));
        }
        setPostalCodeValue(updateValue);

    }
    
    const fetchUserDetails = async ()=>{
        const token = localStorage.getItem("token");
        if(token != null){
            await axios({
                method: "get",
                url: `${base_url}/api/my_profile`,
                headers: { "Accept": "application/json",
                 'Access-Control-Allow-Methods': 'GET, POST', 'Authorization': 'Bearer ' + token },
            }).then((response) => {
                if (response.status != 401) {
                    dispatch(setUserName(response.data.data.name))
                    localStorage.setItem("username",response.data.data.name)
                }
            }).catch(function (error) {
                
            })
        }        
      }
      useEffect(() => {
        fetchUserDetails();
      },[]);
    const onSearch = (searchTerm: any) => {
        // setPostalCodeList(searchTerm);
        setPostalCodeValue(searchTerm);
        setValue("postalCode",searchTerm.toUpperCase())
        // dispatch(fetchPostalCodes(searchTerm));


    };
    const handleBooknow = async () => {
        const isValid = state.postalCodeList.some((item:any) => item.postcode === postalCodeValue.toUpperCase());
        setValue("postalCode",postalCodeValue.toUpperCase())
        const results = await dispatch(fetchGetShopByPinCode(JSON.stringify(postalCodeValue)));
        
        if (!results) {
            navigate("/areaNotCovered");
            return;
        }
        if(results){
            navigate("/productLists");
        }else{
            navigate("/areaNotCovered");
        }
        
    }
    return (
        <>
        <div className="home_single_search">

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className='banner-search-wrap text-center'>
                            <p>Solution for your</p>
                            <h1>Laundry & Dry Cleaning Service</h1>
                            <p>Highest quality and lowest price guaranteed.</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col text-center mb-4'>
                        <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#searchmodal" className='btn gr-btn'><i className="me-2 far fa-calendar-alt"></i>Book Now</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className='home-img-grid'>
                            <ul>
                                <li>
                                    <img src={banner1} alt=""/>
                                </li>
                                <li>
                                    <img src={banner2} alt=""/>
                                </li>
                                <li className='active'>
                                    <img src={banner3} alt=""/>
                                </li>
                                <li>
                                    <img src={banner4} alt=""/>
                                </li>
                                <li>
                                    <img src={banner5} alt=""/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div className="modal fade" id="searchmodal" aria-hidden="true"  data-bs-keyboard="false">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content modal-content-search">    
                    <div className="modal-header pt-0 border-0">
                        <h5>Zipcode</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>                
                    <div className="postcodeform-home">
                        {/* <input type="text" id="postcode-input" value={postalCodeValue} name="pincode" placeholder="Enter Zipcode" className="ui-autocomplete-input" onChange={handleChange} autoComplete="off" /> */}
                        <DebounceInput debounceTimeout={500} value={postalCodeValue} placeholder="Enter Zipcode" className="ui-autocomplete-input" onChange={handleChange} autoComplete="off" />
                        <button disabled={postalCodeValue.length === 0} onClick={handleBooknow} className="btn gr-btn" id="book_now_btn" type="button" data-bs-dismiss="modal"><i className="me-2 far fa-calendar-alt"></i>order now</button>
                        <div className="dropdown">
                            {postalCodeList
                                .filter((item: any) => {
                                    const searchTerm = postalCodeValue.toLowerCase();
                                    const mainValue = item.postcode.toLowerCase();

                                    return (
                                        searchTerm &&
                                        mainValue.startsWith(searchTerm) &&
                                        mainValue !== searchTerm
                                    );
                                })
                                .slice(0, 10)
                                .map((item: any) => (
                                    <div
                                        onClick={() => onSearch(item.postcode)}
                                        className="dropdown-row"
                                        key={item.postcode}
                                    >{item.postcode}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section className='intro-section'>
            <div className="container">
                <div className="row">
                    <div className='col-md-8'>
                        <div className='section-card'>
                            <div className='section-title'>
                                <p>Our Introduction</p>
                                <h2>Welcome To Best at laundry</h2>
                                <img src={titleborder} alt=""/>
                            </div>
                            <div className='intro-content'>
                                <div className='intro-img'>
                                    <img src={trustimg} alt="" />
                                </div>
                                <div className='gr-border'>
                                    <div className="gr-border-grid intro-data-box">
                                        <ul>
                                            <li>
                                                <h2>12K</h2>
                                                <p>Dry Cleaning</p>
                                            </li>
                                            <li>
                                                <h2>20K</h2>
                                                <p>Satisfied Clients</p>
                                            </li>
                                            <li>
                                                <h2>8K</h2>
                                                <p>Happy Customers</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p className='intro-desc'>There are many variations of passag of lorem ipsum available but the majority have suffered alteration in some form by injected humour randomised words which don't look even slightly believable.There are many variations of passag of lorem ipsum available but the majority have suffered alteration in some form by injected humour randomised words which don't look even slightly believable.</p>
                                <div className='intro-content-btn'>
                                    <a className='btn' href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#videomodal"><img src={play} alt=''/></a>
                                    <p>Click on this video to check how we work with Our Clients</p>
                                </div>                            
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='section-card intro-right-grid'>
                            <div className='review-grid text-center'>
                                <h2>Top rated <span>laundry</span></h2>
                                <div className='review-stars'>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <p><NavLink className="nav-bar-link" to={'/reviews'}>View all Reviews</NavLink></p>
                            </div>
                            <div className='gr-border'>
                                <div className="gr-border-grid intro-points-box">
                                    <img src={priorityicon1} alt=''/>
                                    <p>Claim Support</p>
                                    <h5>24/7 Supports</h5>
                                    <p>We love to help you to get your clothes fresh and clean so we are here for you 24/7 for any question, concern or suggestion.</p>
                                </div>
                            </div>
                            <div className='gr-border'>
                                <div className="gr-border-grid intro-points-box">
                                    <img src={priorityicon2} alt=''/>
                                    <p>100% Reliable</p>
                                    <h5>Offers & cares</h5>
                                    <p>Every item sent to dry cleaning or Laundromat, hands of experts who take care of your garments in detailed way.</p>
                                </div>
                            </div>
                            <div className='gr-border'>
                                <div className="gr-border-grid intro-points-box">
                                    <img src={priorityicon3} alt=''/>
                                    <p>Consultation</p>
                                    <h5>Expert teams</h5>
                                    <p>With our subscription, you can set up your services and never worry about laundry or for dry cleaning again.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="videomodal" aria-hidden="true"  data-bs-keyboard="false">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content modal-video">
                        <div className='closebtn'>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>   
                        </div>  
                        <video width="100%" height="auto" autoPlay loop muted>
                            <source src={SampleVideo} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </section>
        <section className='highlight-section'>            
            <div className="container">
                <div className="row">
                    <div className='col-md-12'>
                        <div className='highlight-row'>
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className='highlight-box highlight-bg1'>                            
                                        <div className='highlight-img'>
                                            <img src={wing1} alt="" />
                                            <div className='highlight-overlay'>
                                                <h3>Any Special Cleaning Instructions for us?</h3>
                                                <p>Please Handle carefully, Plink sweater has a delicate embroidery and needs additional care.</p>
                                                <ul>
                                                    <li>
                                                        <img src={laundryicon1} alt=""/><h6>Laundry</h6>
                                                    </li>
                                                    <li>
                                                        <img src={laundryicon2} alt=""/><h6>Dry Cleaning</h6>
                                                    </li>
                                                    <li>
                                                        <img src={laundryicon3} alt=""/><h6>Ironing</h6>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='highlight-content'>
                                            <h4>We pick up your valuable Clothes</h4>
                                            <p>Our delivery services work no matter where you are. Do you need your clothes dropped off at your place of work? We can do that for you. We’ll drop it off at your house, apartment, or even at your hotel.</p>
                                            <ul>
                                                <li><img src={tick} alt=""/>100% Customer Satisfaction</li>
                                                <li><img src={tick} alt=""/>Affordable Prices</li>
                                                <li><img src={tick} alt=""/>Free Collection & Delivery</li>
                                                <li><img src={tick} alt=""/>Best Quality</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='highlight-box highlight-bg2'>                            
                                        <div className='highlight-img'>
                                            <img src={wing2} alt="" />
                                            <div className='highlight-overlay'>
                                                <h3>Order Scheduled</h3>
                                                <p>Download our app or visit our website to start your laundry order.</p>
                                                <ul>
                                                    <li>
                                                        <img src={laundryicon4} alt=""/><h6>Pay Online in Seconds</h6>
                                                    </li>
                                                    <li>
                                                        <img src={laundryicon5} alt=""/><h6>Eco-Friendly</h6>
                                                    </li>
                                                    <li>
                                                        <img src={laundryicon6} alt=""/><h6>Save Time & Money</h6>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='highlight-content'>
                                            <h4>Pack up your valuable Clothes</h4>
                                            <p>Pack the item in a disposable bag, use one bag per service, our driver will transfer them to a reusable laundry heap bag that you can save for your next order, pack one bag per service, no need to count or weight the items, efficient water use</p>
                                            <ul>
                                                <li><img src={tick} alt=""/>100% Customer Satisfaction</li>
                                                <li><img src={tick} alt=""/>Affordable Prices</li>
                                                <li><img src={tick} alt=""/>Free Collection & Delivery</li>
                                                <li><img src={tick} alt=""/>Best Quality</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='highlight-row'>
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className='highlight-box highlight-bg2'>                            
                                        <div className='highlight-img'>
                                            <img src={wing3} alt="" />
                                            <div className='highlight-overlay'>
                                                <h3>Order Scheduled</h3>
                                                <p>Download our app or visit our website to start your laundry order.</p>
                                                <ul>
                                                    <li>
                                                        <img src={laundryicon7} alt=""/><h6>Competitive Price</h6>
                                                    </li>
                                                    <li>
                                                        <img src={laundryicon8} alt=""/><h6>5 Star Rated</h6>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='highlight-content'>
                                            <h4>Wait for driver to pick up Clothes</h4>
                                            <p>Get notified when your driver is nearby. They will pick up your bag and take it to a local cleaning facility. Regular order updates, live driver tracking, zero-emission delivery vehicles, trusted local cleaners, and convenient laundry solutions those help save the environment.</p>
                                            <ul>
                                                <li><img src={tick} alt=""/>100% Customer Satisfaction</li>
                                                <li><img src={tick} alt=""/>Affordable Prices</li>
                                                <li><img src={tick} alt=""/>Free Collection & Delivery</li>
                                                <li><img src={tick} alt=""/>Best Quality</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='highlight-box highlight-bg1'>                            
                                        <div className='highlight-img'>
                                            <img src={wing4} alt="" />
                                            <div className='highlight-overlay'>
                                                <h3>Order Picked</h3>
                                                <p>Download our app or visit our website to start your laundry order.</p>
                                                <ul>
                                                    <li>
                                                        <img src={laundryicon7} alt=""/><h6>24h turnaround time</h6>
                                                    </li>
                                                    <li>
                                                        <img src={laundryicon9} alt=""/><h6>Easy to reschedule</h6>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='highlight-content'>
                                            <h4>We take care of your Clothes</h4>
                                            <p>Items will be meticulously cleaned by a local partner facility. Our driver will bring them whenever you want. You are in complete control of your deliveries and can reschedule at any time if you are not home.24 hour processing time, real-time order status and easy rescheduling</p>
                                            <ul>
                                                <li><img src={tick} alt=""/>100% Customer Satisfaction</li>
                                                <li><img src={tick} alt=""/>Affordable Prices</li>
                                                <li><img src={tick} alt=""/>Free Collection & Delivery</li>
                                                <li><img src={tick} alt=""/>Best Quality</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='marquee-section'>            
            <div className="marquee marquee-grid-one">
                <div className="marquee-inner" aria-hidden="true">
                    <span>Steam iron</span>
                    <span>Laundry services</span>
                    <span>Dry cleaning</span>
                    <span>Commercial laundry</span>
                </div>
            </div>
            <div className="marquee marquee-grid-two">
                <div className="marquee-inner" aria-hidden="true">
                    <span>Steam iron</span>
                    <span>Laundry services</span>
                    <span>Dry cleaning</span>
                    <span>Commercial laundry</span>
                </div>
            </div>
        </section>
        <section className='services-section' id='services-section'>            
            <div className="container">
                <div className="row">
                    <div className='col-md-12'>
                        <div className='section-card'>
                            <div className='section-title2'>
                                <h2>Compare our Services</h2>
                            </div>
                            <div className='services-slider'>
                                <div className="row">
                                    <div className='col-md-3'>
                                        <div className='services-slider-grid services-slider-grid-lbl'>
                                            <img src={serviceicon2} alt=""/>
                                            <h5>&nbsp;</h5>
                                            <ul>
                                                <li>What is included</li>
                                                <li>Typically included items</li>
                                                <li>Service time</li>
                                                <li>Delivery</li>
                                                <li>Items returned</li>
                                                <li>Eco friendly</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='services-slider-grid'>
                                            <img src={serviceicon2} alt=""/>
                                            <h5>Laundry Services</h5>
                                            <ul>
                                                <li>Wash + Tumble-Dry</li>
                                                <li>T-shirts, trousers, bedsheets.</li>
                                                <li>Service time 24h*</li>
                                                <li>Delivery FREE</li>
                                                <li>Items returned in a bag</li>
                                                <li>Eco friendly</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='services-slider-grid'>
                                            <img src={serviceicon3} alt=""/>
                                            <h5>Dry Cleaning Services</h5>
                                            <ul>
                                                <li>Dry Cleaning + Ironing</li>
                                                <li>Suits, shirts, dresses and coats.</li>
                                                <li>Service time 24h*</li>
                                                <li>Delivery FREE</li>
                                                <li>Items returned on Hangers</li>
                                                <li>Eco friendly</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='services-slider-grid'>
                                            <img src={serviceicon4} alt=""/>
                                            <h5>Ironing Services</h5>
                                            <ul>
                                                <li>Ironing</li>
                                                <li>Shirts, t-shirts and bedsheets.</li>
                                                <li>Service time 24h*</li>
                                                <li>Delivery FREE</li>
                                                <li>Items returned in a bag</li>
                                                <li>Eco friendly</li>
                                            </ul>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='faq-section'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className='col-md-12'>
                        <div className='section-card'>
                            <div className="row justify-content-center">
                                <div className='col-md-8'>
                                    <div className='section-title2'>
                                        <h2>Frequently asked questions</h2>
                                    </div>
                                    <div className='faq-content'>
                                        <Accordion defaultActiveKey={["1"]} className="faq-home">
                                            {faqData?.map((item: any)=>{
                                                return <Accordion.Item eventKey={item.id}>
                                                <Accordion.Header>{item.question}</Accordion.Header>
                                                <Accordion.Body>{item.answer}</Accordion.Body>
                                                </Accordion.Item>
                                            })}
                                            {/* <Accordion.Item eventKey={"2"}>
                                            <Accordion.Header>What's the turnaround time?</Accordion.Header>
                                            <Accordion.Body>Bestatlaundry is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers. Bestatlaundry have multiple registered drivers and trusted dry cleaners & laundry service providers to guarantee our successful services delivery to our customers.</Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey={"3"}>
                                            <Accordion.Header>How will I know how much my bill is?</Accordion.Header>
                                            <Accordion.Body>Bestatlaundry is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers. Bestatlaundry have multiple registered drivers and trusted dry cleaners & laundry service providers to guarantee our successful services delivery to our customers.</Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey={"4"}>
                                            <Accordion.Header>Why Choose Our On-Demand Delivery Services?</Accordion.Header>
                                            <Accordion.Body>Bestatlaundry is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers. Bestatlaundry have multiple registered drivers and trusted dry cleaners & laundry service providers to guarantee our successful services delivery to our customers.</Accordion.Body>
                                            </Accordion.Item> */}
                                        </Accordion>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='testimonial-section'>
            <div className="container">
                <div className="row">
                    <div className='col-md-12'>
                        <div className='section-title'>
                            <p>Latest Testimonial</p>
                            <h2>What Our Client Say</h2>
                            <img src={titleborder} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-12'>
                        <div className='testimo-slider'>
                            <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                    },
                                }}
                                >
                                <SwiperSlide>  
                                    <div className='testimonial-card'>
                                        <div className='profile-user'>
                                            <a className='profile-user-img'>
                                                <img src={testimonialuser1}/>   
                                            </a>
                                            <div className='profile-user-info'>
                                                <h6><a>Judy Wells</a></h6>
                                                <p>Chairman and founder</p>
                                            </div>
                                        </div>               
                                        <p>My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='testimonial-card'>
                                        <div className='profile-user'>
                                            <a className='profile-user-img'>
                                                <img src={testimonialuser2}/>   
                                            </a>
                                            <div className='profile-user-info'>
                                                <h6><a>Judy Wells</a></h6>
                                                <p>Chairman and founder</p>
                                            </div>
                                        </div>               
                                        <p>My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.</p>
                                    </div>  
                                </SwiperSlide>
                                <SwiperSlide>  
                                    <div className='testimonial-card'>
                                        <div className='profile-user'>
                                            <a className='profile-user-img'>
                                                <img src={testimonialuser3}/>   
                                            </a>
                                            <div className='profile-user-info'>
                                                <h6><a>Judy Wells</a></h6>
                                                <p>Chairman and founder</p>
                                            </div>
                                        </div>               
                                        <p>My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>  
                                    <div className='testimonial-card'>
                                        <div className='profile-user'>
                                            <a className='profile-user-img'>
                                                <img src={testimonialuser1}/>   
                                            </a>
                                            <div className='profile-user-info'>
                                                <h6><a>Judy Wells</a></h6>
                                                <p>Chairman and founder</p>
                                            </div>
                                        </div>               
                                        <p>My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='testimonial-card'>
                                        <div className='profile-user'>
                                            <a className='profile-user-img'>
                                                <img src={testimonialuser2}/>   
                                            </a>
                                            <div className='profile-user-info'>
                                                <h6><a>Judy Wells</a></h6>
                                                <p>Chairman and founder</p>
                                            </div>
                                        </div>               
                                        <p>My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.</p>
                                    </div>  
                                </SwiperSlide>
                                <SwiperSlide>  
                                    <div className='testimonial-card'>
                                        <div className='profile-user'>
                                            <a className='profile-user-img'>
                                                <img src={testimonialuser3}/>   
                                            </a>
                                            <div className='profile-user-info'>
                                                <h6><a>Judy Wells</a></h6>
                                                <p>Chairman and founder</p>
                                            </div>
                                        </div>               
                                        <p>My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.</p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {blogList?.length > 0 &&
            <section className='latestnews-section'>
                <div className="container">
                    <div className="row">
                        <div className='col-md-12'>
                            <div className='section-title'>
                                <p>ARTICLES</p>
                                <h2>Latest News</h2>
                                <img src={titleborder} alt=""/>
                            </div>                        
                        </div>
                    </div>
                    <div className="row">
                        {blogList?.length > 0 &&
                            <div className='col-md-4'>
                                <div className='latestnews-card'>
                                    <div className='latestnews-date'>
                                        {blogList[0].formatted_date} {blogList[0].formatted_month}
                                    </div>
                                    <div className='latestnews-info-grid'>
                                        <div className='latestnews-img'>
                                            <img src={testimonialimg1} alt=""/>
                                            <div className='latestnews-overlay'>
                                                Trending
                                            </div>
                                        </div>
                                        <div className='latestnews-info'>
                                            <a target='_blank' href={`${blogUrl}/blogg/${blogList[0].id}/${blogList[0].url_slug}`}><h6>{blogList[0].page_title.substring(0, 55)}{blogList[0].page_title.length > 55 ? '...' : ''}</h6></a>
                                            <p>{blogList[0].preview_text.substring(0, 130)}{blogList[0].preview_text.length > 130 ? '...' : ''}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {blogList?.length > 1 &&
                            <div className='col-md-4'>
                                <div className='latestnews-card'>
                                    <div className='latestnews-date'>
                                    {blogList[1].formatted_date} {blogList[1].formatted_month}
                                    </div>
                                    <div className='latestnews-info-grid'>
                                        <div className='latestnews-img'>
                                            <img src={testimonialimg2} alt=""/>
                                            <div className='latestnews-overlay'>
                                                Trending
                                            </div>
                                        </div>
                                        <div className='latestnews-info'>
                                            <a target='_blank' href={`${blogUrl}/blogg/${blogList[1].id}/${blogList[1].url_slug}`}><h6>{blogList[1].page_title.substring(0, 55)}{blogList[1].page_title.length > 55 ? '...' : ''}</h6></a>
                                            <p>{blogList[1].preview_text.substring(0, 130)}{blogList[1].preview_text.length > 130 ? '...' : ''}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {blogList?.length > 2 &&
                            <div className='col-md-4'>
                                <div className='latestnews-card'>
                                    <div className='latestnews-date'>
                                        {blogList[2].formatted_date} {blogList[2].formatted_month}
                                    </div>
                                    <div className='latestnews-info-grid'>
                                        <div className='latestnews-img'>
                                            <img src={testimonialimg3} alt=""/>
                                            <div className='latestnews-overlay'>
                                                Trending
                                            </div>
                                        </div>
                                        <div className='latestnews-info'>
                                            <a target='_blank' href={`${blogUrl}/blogg/${blogList[2].id}/${blogList[2].url_slug}`}><h6>{blogList[2].page_title.substring(0, 55)}{blogList[2].page_title.length > 55 ? '...' : ''}</h6></a>
                                            <p>{blogList[2].preview_text.substring(0, 130)}{blogList[2].preview_text.length > 130 ? '...' : ''}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className='col-md-12'>
                            <div className='loademore-card'>
                                <a target='_blank' href={blogUrl} className='btn gr-btn'>Load More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }
        <section className='mobapp-section'>            
            <div className="container">
                <div className="row">
                    <div className='col-md-12'>
                        <div className='mobapp-subtract-body'>  
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className='mobapp-content'>
                                        <div className='section-title2'>
                                            <h2>We're available on mobile Just download it!</h2>
                                        </div>
                                        <p>Download the our app and request our laundry service. Your clothes washed, folded and delivered to your doorstep. So go ahead and say yes to more time with the family, more happy hours, and more slapping the snooze button every morning — we've got laundry day covered.</p>
                                        <ul className='applinks'>
                                            {settingsData != null && Object.keys(settingsData).length > 0 && 
                                                settingsData?.WebmasterSettings.app_store_link !== '' &&
                                                <li>
                                                    <a target='_blank' href={settingsData?.WebmasterSettings.app_store_link}><img src={appstore} alt=""/></a>
                                                </li>
                                            }
                                            {/* <li>
                                                <a><img src={qrcode} alt=""/></a>
                                            </li> */}
                                            {settingsData != null && Object.keys(settingsData).length > 0 && 
                                            settingsData?.WebmasterSettings.play_store_link !== '' &&
                                                <li>
                                                    <a target='_blank' href={settingsData?.WebmasterSettings.play_store_link}><img src={googleplay} alt=""/></a>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='mobapp-img text-center'>
                                        <img src={appdownload} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </section>        
        </>
    )
}

export default BookNow;